import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentContainer } from "../components/Theme"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import ParallaxBackground from "../components/ParallaxBackground"

const GalleriesWrapper = styled.div`
  /* display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: 768px) {
    justify-content: flex-start;
  } */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
`

const GalleryContainer = styled.div`
  margin-right: 10px;

  h3 {
    color: black;
  }
`

const StyledImg = styled(Img)`
  height: 350px;
  object-fit: cover;
  margin-bottom: 6px;
`
export default function photography({ data }) {
  const galleries = data.allContentfulGallery.edges
  const title = data.allContentfulPhotographyPage.edges[0].node.title
  const subtitle = data.allContentfulPhotographyPage.edges[0].node.subtitle
  const heroImage =
    data.allContentfulPhotographyPage.edges[0].node.heroImage.file.url

  return (
    <Layout>
      <SEO title="Photography" />
      <ParallaxBackground
        bgImage={heroImage}
        bgImageAlt="Blog Page"
        height="25vh"
      >
        <h1 data-sal="fade" data-sal-easing="ease">
          {title}
        </h1>
        <p>{subtitle}</p>
      </ParallaxBackground>
      <ContentContainer style={{ marginTop: "15px" }}>
        <GalleriesWrapper>
          {galleries.map(({ node }) => {
            const title = node.title
            return (
              <GalleryContainer
                key={node.title}
                data-sal="fade"
                data-sal-easing="ease"
              >
                <Link
                  style={{ boxShadow: `none` }}
                  to={`/${node.url}`}
                  onContextMenu={e => e.preventDefault()}
                >
                  <StyledImg fluid={node.images[0].fluid} alt={node.title} />
                  <h3
                    style={{
                      marginTop: 0,
                      marginBottom: "15px",
                    }}
                  >
                    {title}
                  </h3>
                </Link>
              </GalleryContainer>
            )
          })}
        </GalleriesWrapper>
      </ContentContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allContentfulPhotographyPage {
      edges {
        node {
          title
          subtitle
          heroImage {
            file {
              url
            }
          }
        }
      }
    }

    allContentfulGallery {
      edges {
        node {
          images {
            fluid(maxWidth: 400, quality: 75) {
              ...GatsbyContentfulFluid
            }
            title
            description
          }
          title
          url
        }
      }
    }
  }
`
